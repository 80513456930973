export default function AltLogo() {
  return (
    <svg
      className="fill-pink-600 mx-auto my-4"
      width="69.738"
      height="61.1487"
      x="460.6231"
      y="258.1514"
      version="1.1"
      preserveAspectRatio="none"
      viewBox="20.100006103515625 29.899988174438477 159.79998779296875 140.119384765625"
    >
      <g transform="matrix(-1 0 0 1 200 0)">
        <g>
          <path
            d="M96.5 88.2c-.4.3-1.8 1.5-3.9 3.6-2.4 2.4-5.8 6.1-8.5 11.3-1.4 2.6-2.2 5.6-2.3 8.9-.1 3.3.4 6.8 2.1 10 1.6 3.2 4.4 5.9 7.9 7.1 3.6 1.3 7.5 1.7 11.3 1.3 3.8-.4 7.6-1.5 10.5-3.7 2.9-2.2 4.5-5.5 4.7-9 0-.4.1-.8.1-1.3l-.1-1.3-.1-1.3c0-.3-.2-.8-.2-1.2-.2-.8-.3-1.8-.6-2.5-.3-.8-.5-1.5-.8-2.3-1.2-3-3.1-5.5-5.1-7.5-4.1-4.1-8.6-6.3-11.4-8.3-1.4-1-2.5-1.9-3-2.7-.3-.4-.4-.7-.5-.9-.1-.1-.1-.1-.1-.2zm.1-.2s.1.1.2.3c.1.2.3.5.6.8.6.6 1.7 1.5 3.2 2.3 1.5.8 3.4 1.8 5.5 3s4.5 2.7 6.7 4.8c2.2 2.1 4.4 4.7 5.8 7.9.3.8.6 1.7.9 2.6.3.9.4 1.7.6 2.6.1.5.2.8.3 1.4l.1 1.4.1 1.4c0 .5-.1 1-.1 1.5-.2 3.9-2.3 7.9-5.7 10.3-3.4 2.4-7.5 3.5-11.6 3.8-4 .3-8.2-.3-11.9-1.7-3.9-1.5-6.9-4.5-8.5-7.9-1.7-3.4-2.2-7.1-2-10.6.2-3.4 1.2-6.6 2.7-9.2 2.9-5.2 6.5-8.8 8.9-11.2l4.2-3.6v.1z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M84.8 124.9c-2.3-.5-4.5-1.4-6.6-2.5-1-.6-2.1-1.2-3-1.9-1-.7-1.8-1.5-2.6-2.4-.4-.4-.7-1-1.1-1.4-.3-.5-.6-1-.9-1.6-.5-1-.9-2.2-1.2-3.3-.3-1.1-.5-2.3-.7-3.5-.1-1.2-.1-2.4-.1-3.6.1-1.2.2-2.4.5-3.6.3-1.2.7-2.3 1.2-3.4s1.2-2.2 2-3.1c.8-.9 1.7-1.8 2.7-2.6.9-.7 1.9-1.4 2.8-2.1 1-.7 1.9-1.3 2.9-1.9 2-1.2 4-2.4 6.2-3.5l.9-.5.1.9c0 .1.1.2.1.4l.2.4c.1.3.3.5.5.8l1.2 1.5c.9 1 1.9 2 3 2.7l.1.1c-1.3-.5-2.4-1.4-3.5-2.2-.5-.4-1.1-.9-1.5-1.5-.2-.3-.5-.6-.7-.9-.1-.2-.2-.3-.3-.5-.1-.2-.2-.3-.3-.6l1.1.4c-3.9 2.5-7.7 5.2-11.4 7.9-.9.7-1.7 1.4-2.5 2.2-.7.8-1.4 1.7-1.9 2.7-.5 1-.9 2-1.2 3.1-.3 1.1-.5 2.2-.6 3.3-.1 1.1-.1 2.2 0 3.4.1 1.1.2 2.2.5 3.3.2 1.1.6 2.1 1 3.2.2.5.5 1 .7 1.5.3.5.6 1 .9 1.4.7.9 1.5 1.7 2.4 2.4.9.7 1.8 1.4 2.8 2 2 1.2 4.1 2.1 6.4 2.8l-.1.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M110.6 98.5c.3-2 .4-4 .1-5.9-.2-1-.5-1.9-1-2.7l-1.6-2.6-.3-.5.5-.1c1.3-.2 2.6-.3 3.8-.2 1.3 0 2.6.2 3.8.5 2.5.5 4.9 1.5 7.1 2.8l1.6 1 1.5 1.2c1 .8 1.9 1.7 2.8 2.6l1.3 1.4c.5.6.8 1 1.2 1.5.8.9 1.4 2.2 2 3.3.5 1.2 1 2.4 1.2 3.7.2.6.2 1.3.3 2l.1 1-.1 1c-.1.7-.1 1.3-.3 2-.2.6-.3 1.3-.6 1.9-.5 1.2-1.3 2.4-2.1 3.4s-1.8 1.9-2.8 2.7c-.5.4-1.1.7-1.6 1.1-.6.3-1.2.6-1.7.9-.6.3-1.2.5-1.8.7-.6.2-1.2.4-1.8.5-1.2.3-2.5.4-3.8.6v-.2c1.2-.4 2.4-.7 3.5-1.2 1.2-.4 2.2-1 3.3-1.6.5-.3 1-.6 1.5-1s.9-.7 1.4-1.1c.9-.8 1.7-1.6 2.4-2.6.7-.9 1.2-2 1.6-3 .3-.5.4-1.1.5-1.6.2-.5.2-1.1.3-1.7l.1-.8v-.9c0-.6 0-1.1-.2-1.7-.2-1.1-.6-2.2-1-3.3-.5-1-.9-2.1-1.7-3.1-.3-.5-.7-1.1-1-1.5l-1.2-1.4c-.8-.9-1.7-1.8-2.6-2.6l-1.4-1.2-1.5-1c-2-1.3-4.3-2.3-6.7-2.9-2.4-.6-4.9-.8-7.3-.5l.3-.6 1.5 2.6c.5.9.8 1.9 1 2.9.2 2 0 4.1-.4 6l-.2.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M73 95c.5-2.8.1-5.8-1.2-8.3-.2-.3-.3-.6-.5-.9-.2-.3-.4-.6-.6-.8-.5-.5-1-.9-1.6-1.3-.6-.3-1.2-.5-1.9-.6-.7-.1-1.4-.1-2 0l.3-.9c.6.9.9 1.8 1.1 2.8.2 1 .1 2-.2 3-.3 1-.8 1.9-1.5 2.6-.7.7-1.4 1.3-1.9 2-.5.6-1 1.4-1.4 2.1-.4.8-.7 1.6-1 2.4-.5 1.7-.7 3.4-.7 5.1 0 1.7.2 3.5.6 5.2.2.8.5 1.8.7 2.5l.5 1.3.6 1c.5.7 1 1.3 1.7 1.8.6.5 1.4.9 2.2 1.2 1.6.6 3.5.8 5.3.7v.2c-1.8.5-3.8.6-5.7.2-.9-.2-1.9-.6-2.8-1.1-.9-.5-1.6-1.2-2.3-2-.3-.4-.6-.9-.9-1.3l-.6-1.2c-.4-1-.7-1.8-.9-2.8-.5-1.8-.7-3.8-.7-5.7.1-3.8 1.3-7.7 3.9-10.7.6-.7 1.4-1.3 2-1.9.6-.6 1-1.3 1.3-2.1.3-.8.3-1.6.2-2.4-.1-.8-.4-1.6-.8-2.3l-.5-.7.8-.1c.9-.1 1.6-.1 2.4.1.8.2 1.5.5 2.2.9.7.4 1.2.9 1.7 1.5.2.3.5.6.7.9.2.3.4.6.5 1 1.3 2.7 1.5 5.8.8 8.6h.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M119 88.6c-1.2-.8-2.3-1.8-3.2-3-.5-.6-.8-1.2-1.2-1.8-.2-.3-.3-.6-.5-1-.2-.3-.3-.7-.4-1-.1-.3-.2-.8-.2-1.2 0-.4 0-.8.1-1.2.1-.8.5-1.5.9-2.1.4-.6.9-1.2 1.5-1.8.6-.5 1.1-1 1.8-1.4l1-.7-.1 1.3c0 .5 0 1.2.1 1.8.1.6.3 1.2.5 1.8.2.6.5 1.1.8 1.6.3.5.7 1 1.1 1.5.4.5.9.9 1.3 1.3.5.4 1 .7 1.5 1 .6.3 1.1.6 1.7.8l.9.4 1 .4c.6.2 1.2.7 1.8 1.1.6.4 1.1 1 1.6 1.5.4.6.9 1.2 1.2 1.9.4.6.5 1.4.7 2 .2.7.2 1.4.2 2.1 0 .7 0 1.4-.1 2.1-.2 1.4-.6 2.7-1.2 3.9l-.2-.1c.2-1.3.3-2.6.2-3.9 0-.6-.1-1.3-.2-1.9-.1-.6-.3-1.2-.5-1.8-.2-.6-.4-1.1-.8-1.6-.3-.5-.7-1-1-1.4-.4-.4-.8-.9-1.3-1.1l-.7-.5c-.2-.2-.6-.3-.9-.4l-.8-.4-.9-.5c-.6-.3-1.2-.7-1.8-1.1-.6-.4-1.1-.8-1.7-1.3-.5-.5-1-1-1.4-1.5-.4-.6-.8-1.1-1.2-1.8-.3-.6-.6-1.3-.8-1.9-.2-.7-.4-1.4-.5-2-.1-.7-.1-1.4 0-2.1l1 .6c-1.1.7-2.3 1.6-3 2.7-.4.5-.7 1.1-.9 1.8-.1.3-.1.6-.2 1 0 .3 0 .6.1 1s.2.7.3 1c.1.3.3.7.4 1 .3.6.6 1.3 1 1.8.8 1.2 1.7 2.3 2.9 3.1h.1z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M73.1 89.4s.2-.7.9-1.7c.3-.5.8-1.1 1.4-1.8.6-.6 1.3-1.3 2.2-1.9.8-.6 1.8-1.3 2.9-1.9 1.1-.6 2.2-1.1 3.5-1.6 1.3-.5 2.6-.9 3.9-1.3 1.3-.4 2.7-.7 4.1-1 1.4-.2 2.7-.5 4.2-.7 1.4-.2 2.8-.3 4.1-.4 1.3 0 2.7 0 3.9.1 1.2.1 2.4.3 3.5.6 2.1.5 3.8 1.2 4.9 1.9 1.1.7 1.6 1.2 1.6 1.2l-.1.2s-.6-.3-1.7-.7c-1.1-.3-2.8-.7-4.9-.9-1-.1-2.1-.2-3.3-.2h-3.7c-1.3 0-2.6.1-4 .2-1.3.1-2.8.3-4.1.4-1.4.2-2.7.4-4.1.7-1.3.3-2.6.7-3.8 1-1.2.4-2.4.9-3.5 1.4-1.1.5-2 1.1-2.9 1.6-.9.6-1.6 1.2-2.2 1.7-.6.6-1.1 1.1-1.5 1.6-.7 1-1 1.6-1 1.6l-.3-.1z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M118.3 76.3c1.5-3.1 1.8-6.8.9-10-.2-.8-.5-1.6-.9-2.4-.4-.8-.7-1.5-1.2-2.2-.5-.8-.9-1.4-1.5-2.1-.5-.7-1.1-1.3-1.7-1.8-1.2-1.1-2.7-1.9-4.3-2.6-1.6-.7-3.3-1.3-4.9-2.3-.4-.2-.8-.5-1.1-.8-.4-.3-.8-.6-1.1-.9-.7-.6-1.3-1.3-1.9-2-.6-.7-1.1-1.5-1.5-2.3-.4-.8-.8-1.6-1.1-2.6l1.5.3c-1.1 1.1-2.3 2.1-3.5 3.2-1.2 1.1-2.4 2.1-3.7 3l-.2-.1C93 49.4 94 48.2 95 47c1-1.2 2-2.4 3.1-3.6l.9-1 .6 1.4c.3.7.7 1.5 1.2 2.1.5.7.9 1.4 1.4 2 .5.6 1.1 1.2 1.7 1.8.3.3.6.5.9.8.4.3.7.5 1 .8 1.3 1 2.9 1.7 4.5 2.5s3.2 1.8 4.5 3.1c.7.6 1.3 1.3 1.8 2.1.5.7 1.1 1.5 1.5 2.3.4.7.8 1.6 1.2 2.4.3.8.6 1.7.8 2.6.7 3.5.2 7.3-1.6 10.3l-.2-.3z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M107.2 78.4c1.5-.7 2.7-2.1 2.9-3.7.1-.8 0-1.6-.3-2.3-.3-.7-.7-1.5-1.2-2.1-.4-.7-1.1-1.2-1.6-1.9l-1.9-1.7c-1.3-1.1-2.6-2.2-3.8-3.3-2.5-2.3-4.8-4.8-6.8-7.5-1-1.3-2-2.7-2.9-4.2l-.7-1.1-.7-1.1-1.2-2.1 1.5.1-5.2 8.2c-1.7 2.7-3.6 5.5-5.1 8.2-.8 1.4-1.3 2.7-1.3 4.2v2.3c0 .8.1 1.6.2 2.3.5 3.1 1.6 6.2 2.9 9.1l-.3.2c-1.8-2.7-3.3-5.7-4.2-8.9-.2-.9-.4-1.6-.4-2.5-.1-.9-.1-1.7-.1-2.6 0-1.7.5-3.6 1.3-5.1.8-1.5 1.7-2.9 2.5-4.3.9-1.4 1.8-2.7 2.7-4.1l5.5-8.1.8-1.1.7 1.2 1.2 2.2.3.6.3.5.6 1.1c.8 1.4 1.7 2.8 2.7 4.2 1.9 2.7 4.1 5.2 6.5 7.6 1.2 1.2 2.4 2.3 3.6 3.5l1.8 1.8c.5.7 1.2 1.3 1.6 2 .5.7.9 1.5 1.2 2.3.3.8.4 1.7.2 2.6-.3 1.7-1.7 3.1-3.2 3.8l-.1-.3z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M65.8 88c-1.6-.2-3.1-.8-4.5-1.6s-2.6-2.1-3-3.8c-.2-.8-.2-1.7-.1-2.5s.2-1.6.5-2.4c.5-1.6 1.5-2.9 2.3-4.2.9-1.3 1.7-2.6 2.3-4l.9-2.1c.3-.7.5-1.4.7-2.2.4-1.5.7-2.9.9-4.4.1-.7.1-1.5 0-2.2 0-.7-.2-1.5-.3-1.8l-1.8-4 3.4 2.7c1.2.9 2.3 1.8 3.4 2.7 1.1 1 2.2 1.9 3.3 3 1.1 1 2 2.4 2.6 3.7.6 1.3 1.1 2.7 1.5 4.1l-.2.1c-.7-1.3-1.4-2.5-2.2-3.7-.4-.6-.8-1.1-1.3-1.6s-1-.9-1.5-1.4c-1.1-.9-2.2-1.8-3.4-2.6l-3.5-2.6 1.6-1.4c.5 1.1.5 1.9.5 2.7 0 .9-.1 1.7-.2 2.5-.2 1.6-.7 3.2-1.2 4.7s-1.2 3-1.9 4.4c-.8 1.4-1.7 2.8-2.6 4-.9 1.3-1.9 2.5-2.4 3.9-.3.7-.5 1.4-.6 2.1-.1.8-.2 1.5 0 2.2.2 1.5 1.2 2.7 2.5 3.6 1.3.9 2.8 1.5 4.3 1.8v.3z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M126.3 85.7s.7-1 1.9-2.6c.6-.8 1.4-1.8 2.2-3 .4-.6.9-1.2 1.4-1.8.3-.3.5-.6.8-1l.3-.3c.2-.1.2-.2.5 0l.2.2c.2.4.2.5.3.7.5 1.7 1.2 3.4 2.1 5.2.9 1.8 2 3.5 3.3 5.3 1.3 1.8 2.2 4.1 2.9 6.3.7 2.2 1.2 4.6 1.5 6.9.3 2.4.4 4.8.3 7.1-.1 2.4-.6 4.7-1.5 6.9-.3.5-.5 1.1-.8 1.6-.4.5-.6.9-1.1 1.5-.4.4-.8.7-1.1 1.1-.5.3-1 .7-1.4 1-1.9 1.1-3.9 1.7-5.7 2-1.8.4-3.5.4-5 .4s-2.7-.1-3.8-.2c-2.1-.2-3.2-.6-3.2-.6v-.2s1.2.1 3.2 0c1 0 2.3-.1 3.7-.2 1.4-.1 3-.4 4.6-.8 1.6-.4 3.4-1.1 4.9-2.1.3-.3.7-.5 1-.8l1.1-1.1c.3-.4.5-.7.8-1.1.2-.4.4-.9.7-1.3.8-1.9 1.3-4 1.5-6.2.2-2.2.2-4.5 0-6.8s-.6-4.6-1.2-6.7c-.6-2.2-1.4-4.2-2.6-6-1.2-1.8-2.4-3.7-3.2-5.5-.9-1.9-1.4-3.7-1.9-5.4 0-.2-.2-.5-.1-.5v-.1l.2.1-.2.2c-.3.3-.6.6-.8.9-.5.6-1 1.2-1.5 1.7-.9 1.1-1.7 2.1-2.3 2.9-1.3 1.6-2 2.5-2 2.5v-.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M59 103.7s-1.1-.3-2.9-.9c-.9-.3-2.1-.6-3.4-1-1.3-.3-2.8-.8-4.4-1h-.4.1s-.1.1-.1 0c.1-.1 0 .1 0 .3 0 .4 0 .8.1 1.2.1.9.4 1.8.6 2.7.5 1.9.9 3.8 1.4 5.9.5 2 .9 4.2 1.2 6.4.2 1.1.2 2.3.3 3.3.1 1.2.4 2 .9 2.9 1 1.8 2.7 3.1 4.5 4.2 1.8 1.1 3.8 1.8 5.7 2.3 3.9 1.1 7.8 1.3 10.8.5 1.6-.5 3-.9 4.2-1.5 1.2-.6 2.2-1.1 3-1.7.8-.6 1.3-1.1 1.6-1.5.3-.4.4-.6.4-.6l.2.1s-.3 1.2-1.7 2.6c-.7.7-1.7 1.5-2.9 2.2-1.2.7-2.7 1.4-4.3 2-1.7.6-3.6.9-5.7.9-2 0-4.1-.3-6.3-.9-2.1-.6-4.2-1.5-6.2-2.7-1.9-1.3-3.8-2.9-4.9-5.1-.3-.5-.5-1.2-.7-1.8-.2-.6-.2-1.1-.3-1.7-.1-1.3-.1-2.2-.2-3.3-.2-2.1-.6-4.3-.9-6.3-.4-2-.8-4-1.2-5.9-.2-.9-.4-1.9-.5-2.8 0-.5-.1-.9 0-1.4.1-.3 0-.5.4-.9.1-.1.2-.1.2-.1h.6c1.8.3 3.2.8 4.5 1.2 1.3.4 2.4.8 3.4 1.2 1.8.7 2.9 1.1 2.9 1.1v.1z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M58.6 82.6c0-.3-.2-.7-.5-.9-.3-.3-.6-.5-.9-.7-.7-.4-1.4-.7-2.1-1-1.5-.6-3-1-4.6-1.4l-9.3-2.4.9-.7c.3 4.8 1.2 9.6 2.6 14.2.2.6.3 1.1.6 1.7.2.6.4 1.1.6 1.7.2.6.5 1.1.7 1.6.3.5.5 1.1.8 1.5.6 1 1.3 2 2.1 2.9.8.9 1.7 1.8 2.7 2.4l-.1.2c-.6-.2-1.2-.5-1.7-.8-.5-.3-1.1-.7-1.6-1.1-.5-.4-1-.8-1.4-1.3-.4-.5-.9-.9-1.3-1.4-.4-.5-.8-1-1.1-1.6-.3-.5-.7-1.1-.9-1.7l-.5-.8-.4-.9-.6-1.8c-.8-2.4-1.2-4.9-1.4-7.4-.3-2.5-.5-4.9-.5-7.4v-1l.9.3 9.2 2.9c1.5.5 3.1 1 4.5 1.7.7.3 1.5.7 2.1 1.2.3.2.6.5.9.8.2.3.5.7.4 1.2h-.1z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M120.9 82.2s0-1.4.7-3.7c.7-2.2 2.3-5.4 5.5-8.1 1.6-1.4 3.5-2.4 5.5-3.6 2-1.2 4.1-2.4 6.3-3.7 2.2-1.3 4.4-2.6 6.7-4 1.1-.7 2.3-1.4 3.5-2.1l.9-.5.1-.1h.1c.5-.2.2-.1.3-.1h.4c.3 0 .4.2.5.2.3.2.4.4.5.5.5.7.8 1.3 1.1 1.9 1.1 2.6 1.9 5.2 2.5 7.8.6 2.6.9 5.3 1 7.9 0 2.6-.2 5.1-.9 7.4-.7 2.4-1.7 4.4-2.9 6.2-1.1 1.8-2.4 3.2-3.6 4.3-1.2 1.2-2.3 2.1-3.3 2.8-1.9 1.4-3.2 2-3.2 2l-.1-.2s1.1-.8 2.8-2.4c1.7-1.6 4.1-4 6-7.4 1-1.7 1.8-3.6 2.3-5.7.5-2.2.7-4.5.6-7 0-2.4-.3-5-.7-7.5-.5-2.5-1.1-5.1-2.1-7.6-.2-.6-.5-1.2-.8-1.6l-.2-.2s.1.1.2.1h.2c.1 0-.3.1.2-.1h-.1l-.1.1-.9.5c-1.2.7-2.4 1.3-3.5 2-2.3 1.3-4.6 2.6-6.9 3.8-2.2 1.2-4.4 2.4-6.4 3.6-2 1.1-4 2.1-5.6 3.4-3.2 2.5-4.8 5.5-5.6 7.7-.8 2.2-.8 3.6-.8 3.6l-.2-.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M112.7 56c1-2.4 2.5-4.5 4.3-6.4.9-.9 1.9-1.8 2.9-2.6 1.1-.8 2.2-1.4 3.2-2.2 2.1-1.5 4-3.1 5.9-4.9 1.9-1.7 3.7-3.5 5.3-5.4l.8-.9.6 1c1.3 2 2.5 4 3.6 6 .6 1 1.1 2 1.6 3.1s1 2.1 1.4 3.2c.4 1.1.8 2.2 1.1 3.4.3 1.2.5 2.3.6 3.5.2 2.3.3 4.7-.2 7h-.2c-.1-2.3-.5-4.6-1-6.8-.3-1.1-.5-2.2-.9-3.2s-.8-2.1-1.3-3.1c-.9-2.1-2-4.1-3-6.1s-2.1-4.1-3.2-6.1l1.4.1c-3.7 3.7-7.5 7.1-11.9 10-1.1.7-2.2 1.3-3.3 2-1.1.7-2 1.5-3 2.3-1.9 1.7-3.5 3.7-4.5 6l-.2.1z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M49.6 77.8c-.4-2.5-.8-5-1.1-7.5-.1-1.3-.2-2.5-.3-3.8-.1-1.3 0-2.6.1-3.8.3-5 .2-10.1.1-15.1v-.8l.9.1 9 .7c3 .2 6 .5 9 .8.9.1 1.5.2 2.4.6.9.3 1.5.7 2.2 1.1 1.3.9 2.5 1.9 3.6 3 2.2 2.2 4 4.6 5.5 7.2-2-2.2-4.1-4.3-6.4-6.2-1.1-.9-2.3-1.8-3.5-2.5-.6-.3-1.3-.7-1.9-.8-.5-.2-1.4-.3-2-.4-3-.2-6-.6-9-1l-9-1.1.9-.8c0 2.5-.1 5.1-.2 7.6s-.2 5-.5 7.6c-.2 1.3-.4 2.5-.4 3.8 0 1.2 0 2.5.1 3.8.1 2.5.4 5 .7 7.5h-.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M120 47.5c-1.5-1.3-3.3-2.4-4.9-3.5-1.7-1.1-3.4-2.1-5.1-3.5-1.6-1.3-3.1-2.7-4.5-4.2s-2.7-3.1-3.9-4.8h1.2c-1.9 2.6-4.2 4.8-6.8 6.7-.7.5-1.4.9-2.1 1.3-.7.4-1.6.7-2.2 1-.8.3-1.3.6-1.9 1.1-.6.4-1.1.9-1.7 1.3-1.1 1-2.1 2.1-3 3.3-.9 1.2-1.6 2.5-2.2 3.8-.2.7-.6 1.4-.7 2.1-.2.7-.3 1.4-.4 2.2 0 .7-.1 1.5 0 2.2 0 .8.2 1.5.3 2.3l-.3.2c-.3-.7-.7-1.4-.8-2.2-.2-.8-.3-1.6-.3-2.4 0-.8 0-1.6.2-2.4.1-.8.3-1.6.5-2.4.5-1.5 1.2-3 2.1-4.4.9-1.4 2-2.6 3.2-3.7.6-.6 1.3-1.1 2-1.5.7-.4 1.6-.9 2.2-1.2.8-.4 1.4-.5 2.1-.9.7-.3 1.3-.7 1.9-1.1 2.5-1.6 4.8-3.8 6.7-6.1l.6-.8.5.8c2.2 3.3 4.8 6.4 7.8 9 1.5 1.3 3.2 2.5 4.8 3.7 1.6 1.2 3.3 2.4 4.8 3.8l-.1.3z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M48.9 71.6c-2.5-.1-5.1.2-7.5.8-2.4.6-4.8 1.5-6.9 2.8l-12.7 8.3v-1.2c1.7.9 3.2 2 4.7 3.2 1.4 1.2 2.8 2.5 4 4 .6.7 1.1 1.5 1.7 2.3.5.8 1 1.6 1.3 2.5.4.9.7 1.8 1 2.7.2.9.4 2 .5 2.7l.2 1.3c.1.4.3.8.4 1.1.2.7.7 1.5 1.1 2.3.5.7.9 1.4 1.5 2.1.6.7 1.2 1.3 1.9 1.9 2.6 2.4 5.8 4.2 9.2 5.7l-.1.2c-.9-.2-1.8-.5-2.7-.8-.9-.3-1.8-.6-2.6-1-.9-.4-1.7-.8-2.5-1.3s-1.6-1-2.4-1.6c-.7-.6-1.5-1.2-2.1-1.9-.7-.7-1.3-1.4-1.9-2.2-.3-.4-.5-.8-.8-1.3-.3-.4-.5-.9-.6-1.4-.2-.5-.4-1-.5-1.4l-.3-1.3c-.2-1.1-.3-1.7-.5-2.6-.2-.8-.4-1.7-.8-2.4-.3-.8-.7-1.6-1.1-2.3-.4-.7-.9-1.5-1.4-2.2-1.1-1.4-2.3-2.7-3.6-3.9-1.3-1.2-2.8-2.3-4.3-3.2l-1-.6 1-.6 13-7.8c2.3-1.3 4.7-2.1 7.3-2.6 2.5-.5 5.1-.7 7.6-.5l-.1.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M150.9 89c1.8-1.6 4.1-2.8 6.4-3.5 1.2-.4 2.4-.7 3.6-.8.6-.1 1.2-.2 1.8-.2.6-.1 1.3 0 1.9 0 2.5.1 5 .7 7.3 1.6 2.3 1 4.4 2.2 6.4 3.8l1.1.9-1.5.1c-1.2.1-2.4.5-3.5 1s-2.1 1.2-2.9 2.1c-.8.9-1.4 2-1.9 3.1-.4 1.1-.6 2.3-.7 3.6l-.1 3.9-.3 2.1c-.1.7-.3 1.3-.5 2-.4 1.3-1 2.6-1.7 3.7-.3.6-.8 1.1-1.2 1.7-.4.6-.9 1.1-1.4 1.5-.9 1-2.1 1.8-3.2 2.6-1.2.7-2.4 1.4-3.6 1.9-1.2.6-2.6.9-3.8 1.3-1.3.3-2.6.6-3.9.8-1.3.2-2.6.4-4 .5-1.3 0-2.7.2-4 .1-1.3 0-2.6 0-4-.1l-3.9-.4v-.2l3.9-.1 3.9-.3c1.3-.1 2.6-.3 3.9-.5 1.3-.2 2.5-.5 3.8-.7 1.3-.3 2.5-.6 3.7-1 1.2-.5 2.4-.8 3.5-1.4 1.2-.5 2.2-1.1 3.3-1.8 1-.7 2-1.4 2.9-2.3.5-.4.9-.8 1.3-1.3s.8-.9 1.1-1.5c.7-1 1.2-2.1 1.6-3.3.2-.6.4-1.2.5-1.8l.3-1.8.3-3.9c.1-1.4.5-2.7.9-4 .6-1.3 1.3-2.5 2.3-3.5 1-1 2.2-1.8 3.4-2.3 1.3-.5 2.6-.9 4-1l-.4 1c-1.8-1.5-3.8-2.8-6-3.8s-4.5-1.7-6.9-1.8c-1.2-.1-2.4 0-3.6.1-1.2.1-2.4.4-3.5.7-2.3.7-4.5 1.7-6.4 3.2h-.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M49.5 116.5c-1.8 2.3-3.3 4.9-4.6 7.5-1.3 2.6-2.3 5.4-3 8.2-.4 1.4-.6 2.9-1 4.3-.2.7-.4 1.5-.7 2.2-.3.7-.6 1.4-1 2.1-.4.7-.8 1.3-1.3 1.9-.5.6-1.1 1.2-1.7 1.7-.6.5-1.3.9-2 1.3-.8.3-1.5.6-2.4.7l.7-1c.3.8.7 1.7 1.3 2.4.3.4.5.8.8 1.1.3.3.6.7 1 1 .4.3.7.6 1.1.9.4.3.7.6 1.2.7.4.2.8.5 1.2.6.4.1.9.3 1.3.5l1.3.3c.4.1.9.1 1.4.1.5 0 .9.1 1.4 0l1.4-.1c.5 0 .9-.1 1.4-.2l1.4-.2 2.8-.7h-.1c2.2-1 4.4-2.1 6.5-3.3 1.1-.6 2.1-1.2 3.1-1.9s2-1.4 2.7-2.3l.2.1c-.5 1.2-1.5 2-2.4 2.9-.9.8-1.9 1.6-2.9 2.3-2 1.4-4.1 2.7-6.3 3.8h-.1l-2.9 1-1.5.3-1.5.3c-1.1 0-2.1.2-3.2.1-.5-.1-1.1-.1-1.6-.2l-1.6-.4-1.5-.6c-.5-.2-1-.5-1.4-.8-.5-.2-.9-.6-1.3-.9-.4-.3-.8-.7-1.2-1.1-.4-.4-.7-.8-1.1-1.2-.3-.4-.6-.9-.9-1.3-.5-.9-1-1.8-1.3-2.9l-.3-.9 1-.1c.5 0 1.3-.2 1.9-.5.6-.3 1.2-.6 1.8-1 .6-.4 1.1-.9 1.5-1.4.5-.5.9-1.1 1.3-1.7.4-.6.7-1.2 1-1.9.3-.6.5-1.3.7-2 .4-1.4.7-2.8 1.1-4.3.9-2.9 2-5.6 3.5-8.2 1.4-2.6 3.1-5.1 5-7.4l-.2.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M67.7 131.9s-.8 1.4-1.7 4c-.5 1.3-1 2.9-1.3 4.8-.3 1.9-.5 4.1-.1 6.4.1.6.2 1.2.4 1.7.1.6.4 1.2.7 1.7.6 1.1 1.3 2.2 2.2 3.2 1.8 2.1 3.9 4 6.2 5.9 2.3 1.9 4.8 3.8 7.3 5.7.6.5 1.2 1 1.9 1.6.3.3.6.6.9 1 .1.3.4.5.2 1.3-.1.2-.1.1-.1.2-.1 0 .1.1.1-.5 0-.4-.6-.6-.6-.6h-.3.1c1.2-.7 2.6-1.7 3.9-2.6 1.3-.9 2.7-1.8 4.2-2.6.7-.4 1.5-.8 2.3-1l1.2-.4c.4-.1.7-.3 1-.4 2.8-1.2 5.4-2.8 7.8-4.5 2.4-1.7 4.5-3.6 5.9-5.7.7-1.1 1.2-2.2 1.3-3.2l.1-.4v-.4c0-.3 0-.6-.1-.9-.1-.5-.2-1.1-.4-1.6-.7-2.2-1.7-4.1-2.6-5.7-1-1.6-2-3-2.8-4.2-1.7-2.2-2.7-3.4-2.7-3.4l.1-.1s1.2 1 3.1 3.1c1 1 2.1 2.4 3.2 4s2.4 3.5 3.2 5.9c.2.6.4 1.3.5 1.9.1.3.1.6.1.9l.1.5v.6c0 1.5-.7 2.9-1.4 4.2-1.6 2.5-3.8 4.6-6.3 6.4-2.5 1.8-5.3 3.4-8.4 4.6-.4.1-.8.3-1.2.4l-1.1.3c-.7.2-1.4.5-2.1.9-1.4.7-2.8 1.5-4.1 2.3-1.4.8-2.6 1.7-4.2 2.6 0 0-.1.1-.2.1s0 0-.3.1h-.3c0 .1-.6-.2-.6-.6 0-.7.2-.7.2-.7s.1-.2 0 0c-.1.4 0 .2-.1.2-.1-.2-.4-.5-.7-.8-.5-.5-1.1-1-1.7-1.5-2.4-2-4.9-3.9-7.1-5.9-2.3-2-4.4-4-6.2-6.2-.9-1.1-1.6-2.2-2.2-3.5-.3-.6-.5-1.2-.6-1.8-.2-.6-.3-1.2-.4-1.8-.3-2.4 0-4.7.4-6.6.4-1.9 1-3.5 1.5-4.8 1-2.6 1.9-4 1.9-4l-.1-.1z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M106.1 135.4c4.8 2.7 9.9 5 15.1 6.7 1.3.4 2.6.8 4 1.2 1.3.4 2.7.6 4 .8a28.28 28.28 0 0 0 8.2 0c5.4-.8 10.6-2.8 15.8-4.6 1.3-.5 2.7-.9 4.1-1.2.7-.1 1.5-.3 2.4-.2l.7.1.5.2 1 .4c2.6 1.2 5 2.5 7.4 4l-1.5 1.3c-1.4-3-3.2-6-5-8.8-.9-1.4-2-2.7-3.1-4l-.9-.9-.2-.2-.1-.1-.1-.1-.5-.4c-.3-.3-.7-.5-1-.7-.3-.2-.6-.4-1-.6-1.5-.8-3.1-1.4-4.7-2-1.6-.6-3.3-1.1-4.9-1.6-3.3-.9-6.7-1.7-10.2-2.4v-.2c3.5.2 7 .7 10.4 1.4 3.5.7 6.9 1.6 10.2 3.2.8.4 1.8.9 2.4 1.5l.5.4.1.1.2.2.2.2c.3.3.7.6 1 1 1.3 1.3 2.3 2.7 3.3 4.2s1.9 3 2.8 4.5c.8 1.5 1.6 3.1 2.3 4.7l1.3 3-2.8-1.7c-2.3-1.5-4.7-2.9-7.1-4.1l-.9-.4-.5-.2c-.1 0-.2 0-.3-.1-.5-.1-1.1 0-1.8.1-1.3.3-2.6.7-3.9 1.1-5.2 1.7-10.6 3.6-16.2 4.3-1.4.2-2.9.3-4.2.2-1.4 0-2.8-.2-4.2-.4-1.4-.3-2.8-.6-4.1-1-1.4-.4-2.7-.8-4-1.3-5.2-1.9-10.2-4.4-15-7.2l.3-.4z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M108.7 153.9c3 1.3 6.2 2.2 9.3 2.9 1.6.3 3.2.6 4.8.8.8.1 1.6.2 2.4.2.8 0 1.6.1 2.4.1h9.8c3.3 0 6.6-.1 9.8 0l-1.1 1c.2-2.8.4-5.6.4-8.4 0-.7 0-1.4-.1-2.1 0-.3-.1-.7-.1-1-.1-.3-.1-.6-.3-.9-.5-1.3-.9-2.7-1.4-4l.2-.1c.8 1.2 1.5 2.5 2.2 3.7.2.3.3.7.5 1.1.1.4.2.7.3 1.1.2.7.3 1.4.3 2.2.3 2.9.3 5.7.2 8.6l-.1 1h-1c-3.3-.1-6.6-.3-9.8-.4l-9.8-.5c-.8 0-1.7-.1-2.5-.2s-1.7-.2-2.5-.3c-1.6-.3-3.3-.7-4.8-1.1-3.2-.9-6.3-2-9.2-3.4l.1-.3z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
          <path
            d="M162.6 114.9c1.7.9 3.2 1.9 4.7 3.1.7.6 1.4 1.3 2 2l.9 1.2.7 1.2c.9 1.5 2.1 2.9 3.4 4.2 1.3 1.3 2.6 2.6 4 3.7l1.6 1.3-2.2.3c-3 .4-6 .5-9 .2-1.5-.1-3-.3-4.4-.7-1.5-.3-2.9-.7-4.3-1.3v-.2l4.4.1h13.1l-.5 1.6c-1.4-1.3-2.7-2.7-3.9-4.1-.6-.7-1.2-1.5-1.8-2.2-.6-.8-1.1-1.6-1.5-2.5l-.6-1.2c-.2-.4-.4-.8-.7-1.1-.5-.7-1.1-1.4-1.8-2.1-1.3-1.3-2.8-2.5-4.3-3.5h.2z"
            data-color="1"
            className="aLgOaV3zRcolors-00983f593-32f8-44ac-b109-4ebe83caaa14"
          ></path>
        </g>
      </g>
    </svg>
  );
}
